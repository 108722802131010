import React from 'react';

import Container from '../Container/Container';

import '../PublicOffer/PublicOffer.scss';

const Security: React.FC = () => {
  return (
    <div className="public-offer">
      <Container>
        <h1>Політика безпеки інтернет-магазину будматеріалів</h1>
        <p>
          Адміністрація сайту <a href="https://cogu.com.ua">https://cogu.com.ua</a> зобов'язується зберігати вашу конфіденційність в
          Інтернеті. Ми приділяємо велике значення охороні наданих вами даних. Наша політика конфіденційності заснована на вимогах
          "Загального регламенту про захист персональних даних Європейського Союзу (GDPR)". Цілі, в яких ми збираємо персональні дані:
          поліпшення роботи нашого сервісу, здійснення контактів з відвідувачами даного сайту, обробка даних користувачів для сервісу онлайн
          - торгівлі, здійснення послуг, пов'язаних з напрямком діяльності даного сайту, а так само для таких дій.
        </p>
        <h2>Збір та використання персональних даних</h2>
        <p>
          Ми збираємо і використовуємо ваші персональні дані тільки в разі вашого добровільної згоди. При згоді з цим ви дозволяєте нам
          збирати і використовувати такі дані: ім'я та прізвище, електронна пошта, номер телефону. Збір та обробка ваших даних проводиться
          відповідно до законів, що діють на території Європейського Союзу і в державі Україна.
        </p>
        <h2>Зберігання даних, зміна і видалення</h2>
        <p>
          Користувач, який надав свої персональні дані сайту petrovich.kh.ua має право на їх зміну і видалення, а так само на відгук своєї
          згоди з їх використанням. Термін, протягом якого будуть зберігатися ваші персональні дані: час, необхідний для використання даних
          для основної діяльності сайту. При завершенні використання ваших даних адміністрація сайту видаляє їх. Для доступу до своїх
          персональних даних ви можете зв'язатися з адміністрацією сайту за наступною адресою: info@***.com. Ми можемо передавати ваші
          особисті дані третім сторонам тільки з вашого добровільної згоди, якщо вони були передані, то зміна даних у інших організаціях,
          які пов'язані з нами, ми здійснити не можемо.
        </p>
        <h2>Використання технічних даних при відвідуванні сайту</h2>
        <p>
          При відвідуванні вами сайту <a href="https://cogu.com.ua">https://cogu.com.ua</a> в базі даних зберігаються записи про вашу IP
          адресу, часу відвідування, настройках браузера, операційної системи, а також інша технічна інформація необхідна для коректного
          відображення вмісту сайту. За цими даними нам неможливо ідентифікувати особу відвідувача.
        </p>
        <h2>Надання інформації дітьми</h2>
        <p>
          Якщо Ви є батьком або опікуном, і ви знаєте, що ваші діти надали нам свої особисті дані без Вашої згоди, зв'яжіться з нами:
          info@***.com. На нашому сервісі заборонено залишати особисті дані неповнолітніх без згоди батьків або опікунів. 
        </p>
        <h2>Використання cookies</h2>
        <p>
          Для коректного відображення вмісту і для зручності використання сайту <a href="https://cogu.com.ua">https://cogu.com.ua</a> ми
          використовуємо cookie файли. Це невеликі файли, які зберігаються на вашому пристрої. Вони допомагають сайту запам'ятати інформацію
          про вас, наприклад якою мовою ви переглядаєте сайт і які сторінки ви вже відкривали, ця інформація буде корисна при наступному
          відвідуванні. Завдяки файлів cookie перегляд сайту стає значно зручнішим. Ви можете налаштувати прийом або блокування cookie в
          браузері самостійно. Неможливість приймати cookie може обмежити працездатність сайту.
        </p>
        <h2>Використання персональних даних іншими сервісами</h2>
        <p>
          На цьому сайті використовуються сторонні інтернет-сервіси, які здійснюють незалежний від нас збір інформації: Google Analytics,
          Google AdSense, Facebook.com. Зібрані ними дані можуть надаватися іншим службам всередині цих організацій, вони можуть
          використовувати дані для персоналізації реклами своєї власної рекламної мережі. Ви можете прочитати призначені для користувача
          угоди цих організацій на їх сайтах. Там же ви можете відмовитися від збору ними персональних даних, наприклад налаштувати
          блокувальник Google Analytics на своеём пристрої. Ми не передаємо персональні дані іншим організаціям і службам, не зазначених в
          цій політиці конфіденційності. Виняток становить тільки передача інформації при законних вимогах державних органів уповноважених
          здійснювати дані дії.
        </p>
        <h2>Посилання на інші сайти</h2>
        <p>
          Наш сайт <a href="https://cogu.com.ua">https://cogu.com.ua</a> може містити посилання на інші сайти, які не справляються нами. Ми
          не несемо відповідальність за їх зміст. Ми рекомендуємо вам ознайомитися з політикою конфіденційності кожного сайту, який ви
          відвідуєте, якщо вона там є.
        </p>
        <h2>Зміни в політиці конфіденційності</h2>
        <p>
          Наш сайт <a href="https://cogu.com.ua">https://cogu.com.ua</a> може оновлювати нашу політику конфіденційності час від часу. Ми
          повідомляємо про будь-які зміни, розмістивши нову політику конфіденційності на цій сторінці. Ми відстежуємо зміни законодавства,
          що стосується персональних даних в Європейському Союзі і в державі Україна. Якщо ви залишили персональні дані у нас, то ми
          повідомимо вас про зміну в політиці конфіденційності. Якщо ваші персональні дані були введені не коректно, то ми не зможемо з вами
          зв'язатися.
        </p>
        <h2>Зворотній зв'язок, прикінцеві положення</h2>
        <p>
          Зв'язатися з адміністрацією сайту <a href="https://cogu.com.ua">https://cogu.com.ua</a> з питань, пов'язаних з політикою
          конфіденційності можна за адресою info@***.com, або за допомогою контактної форми зазначеної у відповідному розділі.
        </p>
      </Container>
      <div className="blue-circle" />
      <div className="yellow-circle" />
    </div>
  );
};

export default Security;
