import React from 'react';

import Seo from '../components/SEO/SEO';

import { SEO_ITEMS } from '../constants/SEOItems';
import Security from '../components/Security/Security';
import Subheader from '../components/Subheader/Subheader';

const crumbs = [
  {
    title: 'Головна',
    link: '/',
  },
  {
    title: 'Політика безпеки інтернет-магазину будматеріалів',
    link: '/security',
  },
];

const securityPage: React.FC = (): JSX.Element => {
  return (
    <>
      <Seo
        breadcrumbs={crumbs}
        description={SEO_ITEMS.securityPage.description}
        lang="ua"
        path="/security"
        title={SEO_ITEMS.securityPage.title}
      />
      <Subheader crumbs={crumbs} withContainer />
      <Security />
    </>
  );
};

export default securityPage;
